import { useEffect, useReducer, useState } from "react";
import Preview from "./compontents/preview";
import { apiURL } from "./lib/constants";
function App() {
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [mergedPdf, setMergedPdf] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedFiles) {
      let id = -1;
      const newSelectedFiles = Object.keys(selectedFiles).map((key) => {
        id += 1;
        return {
          name: selectedFiles[key].name,
          id,
          order: id,
        };
      });
      console.log("selectedFiles", newSelectedFiles);
      dispatch({ type: "newPreviews", previews: newSelectedFiles });
    }
  }, [selectedFiles]);

  function dragged(event) {
    console.log(event, " is beeing dragged");
  }

  function reducer(state, action) {
    switch (action.type) {
      case "updateY":
        const unsortedPreviews = state.previews.map((preview) => {
          if (preview.id === action.id) {
            return { name: preview.name, id: preview.id, y: action.y };
          } else {
            return preview;
          }
        });

        const sortedPreviews = unsortedPreviews.sort((a, b) => {
          return a.y - b.y;
        });

        return {
          previews: sortedPreviews,
        };
      case "newPreviews":
        console.log("newPreviews ", action.previews);
        return { previews: action.previews };

      case "switchOrder":
        if (action.direction === "up") {
          const toChangeUp = state.previews.findIndex((preview) => {
            return preview.id === action.id;
          });

          if (state.previews[toChangeUp].order > 0) {
            const newUnorderdPreviews = state.previews.map((preview) => {
              if (preview.order === state.previews[toChangeUp].order) {
                return { ...preview, order: preview.order - 1 };
              } else if (
                preview.order ===
                state.previews[toChangeUp].order - 1
              ) {
                return { ...preview, order: preview.order + 1 };
              } else {
                return { ...preview };
              }
            });

            return {
              previews: newUnorderdPreviews.sort((a, b) => {
                return a.order - b.order;
              }),
            };
          } else {
            return state;
          }
        } else if (action.direction === "down") {
          const toChangeDown = state.previews.findIndex((preview) => {
            return preview.id === action.id;
          });

          const higherAvailable = state.previews.findIndex((preview) => {
            return preview.order === state.previews[toChangeDown].order + 1;
          });

          if (higherAvailable !== -1) {
            const newUnorderdPreviews = state.previews.map((preview) => {
              if (preview.order === state.previews[toChangeDown].order) {
                return { ...preview, order: preview.order + 1 };
              } else if (
                preview.order ===
                state.previews[toChangeDown].order + 1
              ) {
                return { ...preview, order: preview.order - 1 };
              } else {
                return { ...preview };
              }
            });
            return {
              previews: newUnorderdPreviews.sort((a, b) => {
                return a.order - b.order;
              }),
            };
          } else {
            return state;
          }
        }
        return;
      default:
        throw new Error("something went wrong");
    }
  }

  const initialState = {
    previews: [],
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  function handleChange(event) {
    setSelectedFiles(event.target.files);
  }

  function readData(file) {
    return new Promise(function (resolve, reject) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };

      reader.onerror = function () {
        reject(reader);
      };
    });
  }
  async function mergePdfs(event) {
    event.preventDefault();

    setLoading(true);
    let files = selectedFiles;
    const numFiles = files.length;

    let sortedFileList = [];
    let filesArray = [].slice.call(files);

    for (let i = 0; i < numFiles; i++) {
      const indexPreviews = state.previews.findIndex((preview) => {
        return preview.order === i;
      });

      const indexFiles = filesArray.findIndex((preview) => {
        return preview.name === state.previews[indexPreviews].name;
      });

      sortedFileList.push(filesArray[indexFiles]);
    }

    console.log(sortedFileList);
    let pdfs = [];

    for (let i = 0; i < sortedFileList.length; i++) {
      const dataAsURL = await readData(sortedFileList[i]);
      pdfs.push(dataAsURL);
    }
    const url = apiURL + "/merge";

    const response = await fetch(url, {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      body: JSON.stringify(pdfs),
    }).catch((error) => {
      setLoading(false);
      console.log(error);
    });

    const blob = await response.blob();

    setLoading(false);

    setMergedPdf(URL.createObjectURL(blob));
  }
  return (
    <div className="container mx-auto font-body">
      <div className="py-10">
        <h1 className=" font-bold text-2xl text-center">
          Welcome to pdfmerger n1c0l4
        </h1>
      </div>

      <form
        onSubmit={(event) => {
          mergePdfs(event);
        }}
      >
        <div className="flex flex-col items-center">
          <input
            type="file"
            onChange={handleChange}
            multiple
            accept="application/pdf"
            className="mb-10"
          />
          <button
            type="submit"
            disabled={loading}
            className={
              "px-4 py-2 rounded-md bg-pink-800 text-white items-center text-center disabled:bg-pink-500"
            }
          >
            {loading && (
              <span>
                <svg
                  className="animate-spin h-5 w-5 mr-3"
                  viewBox="0 0 100 101"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
            )}
            Merge!
          </button>

          <div className="py-10 mb-5">
            {state.previews.map((preview) => {
              return (
                <Preview
                  name={preview.name}
                  key={preview.name}
                  id={preview.id}
                  dragged={dragged}
                  moveOrder={(idd, direction) => {
                    dispatch({ type: "switchOrder", id: idd, direction });
                  }}
                />
              );
            })}
          </div>

          {mergedPdf ? (
            <a
              download
              href={mergedPdf}
              className=" px-4 py-2 rounded-md bg-pink-800 text-white items-center text-center "
            >
              Download your merged pdf
            </a>
          ) : (
            ""
          )}
        </div>
      </form>
    </div>
  );
}

export default App;
