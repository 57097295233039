export default function Preview({ name, dragged, moveOrder, id }) {
  return (
    <div draggable className="py-4 flex gap-5 items-center" onDrag={dragged}>
      <h3 className=" font-bold text-lg">{name}</h3>

      <div className="flex flex-col gap-2">
        <img
          src="up.svg"
          alt=""
          className="h-4"
          onClick={() => moveOrder(id, "up")}
        />
        <img
          src="down.svg"
          alt=""
          className="h-4"
          onClick={() => moveOrder(id, "down")}
        />
      </div>
    </div>
  );
}
